import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import Pagination from "../../pages/Pagination";
import Male from "../../assets/images/male.png";
import dayjs from "dayjs";
import { Toast } from "../../../src/util/Toast";

import { baseURL, key } from "../../util/Config";

const CoinSeller2 = () => {
  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchDialog, setSearchDialog] = useState("");

  // New state variables for mobile number dialog
  const [openMobileDialog, setOpenMobileDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [mobile, setmobile] = useState("");

  const [openCoinDialog, setOpenCoinDialog] = useState(false);
const [selectedCoinSellerId, setSelectedCoinSellerId] = useState(null);
const [coinAmount, setCoinAmount] = useState(0);

const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
const [selectedSellerId, setSelectedSellerId] = useState(null);


  useEffect(() => {
    // Fetch the coin sellers from your API (replace with the actual API call)
    fetchCoinSellers();
  }, []);

  // const fetchCoinSellers = async () => {
  //   // Replace with actual API call logic
  //   const response = await fetch("/api/getCoinSeller");
  //   const result = await response.json();
  //   setData(result);
  // };

  const fetchCoinSellers = async () => {
    try {
      const response = await fetch(`${baseURL}users-coin-sellers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: key, // API key from config
        },
      });
      console.log("response: ", response);
      const result = await response.json();
      console.log("result: ", result);
      setData(result.users); // Assuming result contains a `coinSellers` array
    } catch (error) {
      console.error("Error fetching coin sellers:", error);
    }
  };
  

  

  const handleOpen = () => {
    setOpenDialog(true); // Open the dialog
    fetchUsersForCoinSeller(); // Fetch users when dialog opens
  };

  const handleClose = () => {
    setOpenDialog(false); // Close the dialog
    fetchCoinSellers();
  };

  const fetchUsersForCoinSeller = async () => {
    try {
      const response = await fetch(`${baseURL}search-user-for-coin-seller`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          key: key, // Add key in headers
        },
      });
      const result = await response.json();
      console.log("result: ", result);
      setUsers(result.users); // Assuming the API returns data in this format
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase();
    if (value) {
      const filteredData = data.filter((item) => {
        return (
          item?.name?.toUpperCase()?.includes(value) ||
          item?.email?.toUpperCase()?.includes(value) ||
          item?.receiveCoin?.toString()?.includes(value) ||
          item?.spendCoin?.toString()?.includes(value)
        );
      });
      setData(filteredData);
    } else {
      fetchCoinSellers(); // Reset data to original when search is cleared
    }
  };

  const handleDialogSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchDialog(value);

    if (value) {
      const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(value)
      );
      setUsers(filteredUsers);
    } else {
      fetchUsersForCoinSeller(); // Reset to the full user list if the search is empty
    }
  };

  const makeCoinSeller = async (user) => {
    if (!user.mobile) {
      // Open mobile number dialog
      setSelectedUserId(user._id);
      setOpenMobileDialog(true);
    } else {
      // Proceed with API call
      await callMakeCoinSellerApi(user._id, user.mobile);
    }
  };

  const handleMobileDialogClose = () => {
    setOpenMobileDialog(false);
    setmobile("");
    setSelectedUserId("");
  };

  const handleMobileSubmit = async () => {
    if (!mobile) {
      Toast("error", "Please enter mobile no.");
      return;
    }
    if (mobile.length !== 10 || !/^\d{10}$/.test(mobile)) {
      Toast("error", "Mobile number should be exactly 10 digits");
      return;
    }
    // Proceed with API call with mobile
    await callMakeCoinSellerApi(selectedUserId, mobile);
    // Close the mobile number dialog
    handleMobileDialogClose();
  };

  const callMakeCoinSellerApi = async (userId, mobileNumber) => {
    try {
      const payload = {
        userId: userId, // Pass the user ID in the request body
      };

      if (mobileNumber) {
        payload.mobile = mobileNumber;
      }

      const response = await fetch(`${baseURL}coinSeller/make-coin-seller`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("Make Coin Seller Response:", result);
      // Handle success or error response as needed
      if (result.status) {
        // alert("User has been made a coin seller successfully.");
        Toast("success", result.message);
        // Refresh the coin sellers list

        fetchUsersForCoinSeller();
      } else {
        Toast("error", result.message);
      }
    } catch (error) {
      console.error("Error making coin seller:", error);
    }
  };

  const handleGiveCoinClick = (coinSellerId) => {
    console.log("coins seller ID: ", coinSellerId);
    setSelectedCoinSellerId(coinSellerId); // Store the selected seller's ID
    setOpenCoinDialog(true); // Open the dialog
  };
  

  const handleGiveCoin = async (userId, coin) => {
    try {
      const payload = {
        userId: userId,
      };

      if (coin) {
        payload.coin = coin;
      }

      const response = await fetch(`${baseURL}coinSeller/give-coin-to-coin-seller`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("Give coin to Seller Response:", result);
      // Handle success or error response as needed
      if (result.status) {
        // alert("User has been made a coin seller successfully.");
        Toast("success", result.message);
        // Refresh the coin sellers list
        setOpenCoinDialog(false);

        fetchCoinSellers();
      } else {
        Toast("error", result.message);
        setOpenCoinDialog(false);

      }
    } catch (error) {
      console.error("Error giving  coin to seller:", error);
    }
  };


  const handleRemoveCoinSellerClick = (coinSellerId) => {
    console.log("coins seller ID: ", coinSellerId);
    setSelectedSellerId(coinSellerId); // Store the selected seller's ID
    setOpenDeleteDialog(true); // Open the dialog
  };


  const handleDeleteSeller = async (userId) => {
    try {
      const payload = {
        userId: userId,
      };

      

      const response = await fetch(`${baseURL}coinSeller/delete-coin-seller`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          key: key,
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      console.log("delete coin seller Response:", result);
      if (result.status) {
        Toast("success", result.message);
        setOpenDeleteDialog(false);

        fetchCoinSellers();
      } else {
        Toast("error", result.message);
        setOpenDeleteDialog(false);

      }
    } catch (error) {
      console.error("Error deleting coin seller:", error);
    }
  };
  

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Coin Seller
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  CoinSeller
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="CoinSellerAdd"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <div className="input-group-prepend border-0">
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                      <input
                        type="search"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={handleSearch}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow pt-0">
              <table className="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    {/* <th>Email</th> */}
                    <th>Mobile No</th>
                    <th>Receive Coin</th>
                    <th>Spend Coin</th>
                    <th>Coin</th>
                    <th>Give Coin</th>
                    <th>Remove Coin Seller</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            height="50px"
                            width="50px"
                            alt="app"
                            src={item?.image || Male}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              float: "left",
                              objectFit: "cover",
                            }}
                          />
                        </td>
                        <td>{item?.name}</td>
                        {/* <td>{item?.email}</td> */}
                        <td>{item?.coinSellerDetails[0]?.mobileNo}</td>
                        <td>{item?.coinSellerDetails[0]?.receiveCoin}</td>
                        <td>{item?.coinSellerDetails[0]?.spendCoin}</td>
                        <td>{item?.coinSellerDetails[0]?.coin}</td>
                        <td>
                            <Tooltip title="Give Coin">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                // onClick={() => handleGiveCoin(item?.coinSellerDetails[0]?._id)}
                                onClick={() => handleGiveCoinClick(item?.coinSellerDetails[0]?._id)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                        </td>
                        <td>
                          <Tooltip title="Remove Coin Seller">
                            <button
                              type="button"
                              className="btn btn-sm btn-danger"
                              onClick={() => handleRemoveCoinSellerClick(item?._id)}
                            >
                              <i className="fa fa-trash fa-lg"></i>
                            </button>
                          </Tooltip>
                        </td>

                        <td>
                          {dayjs(item?.createdAt).format("DD MMM YYYY")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <p>No Data Found</p>
                  )}
                </tbody>
              </table>
              <div className="mt-2">
                <Pagination
                  activePage={activePage}
                  rowsPerPage={rowsPerPage}
                  userTotal={data && data.length}
                  handlePageChange={handlePageChange}
                  handleRowsPerPage={handleRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog to show users fetched for Coin Seller */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        // fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>Choose Users for Coin Seller</DialogTitle>
        <DialogContent>
          <TextField
            label="Search Users"
            value={searchDialog}
            onChange={handleDialogSearch}
            fullWidth
            margin="dense"
            InputLabelProps={{
              style: { color: "#8fe3eb" }, // Label text color
            }}
            InputProps={{
              style: { color: "#eb9f8f" }, // Input text color
            }}
          />
          <table className="table table-striped mt-3">
            <thead>
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Username</th>
                <th>Mobile No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {users && users.length > 0 ? (
                users.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.username}</td>
                    <td>{user.mobile || "N/A"}</td>
                    {/* <td>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => makeCoinSeller(user)}
                      >
                        Make Coin Seller
                      </Button>
                    </td> */}
                    <td>
                      <Button
                        variant="contained"
                        color={user.isCoinSeller ? "secondary" : "primary"} // Change color if already a seller
                        onClick={() => !user.isCoinSeller && makeCoinSeller(user)} // Only allow click if not already a seller
                        disabled={user.isCoinSeller} // Disable button if user is already a seller
                        style={{
                          backgroundColor: user.isCoinSeller ? "#e0e0e0" : "", // Light gray background if disabled
                          color: user.isCoinSeller ? "#ff5722" : "#fff", // Orange text color for disabled, white for active
                          cursor: user.isCoinSeller ? "not-allowed" : "pointer", // Pointer cursor for enabled, not-allowed for disabled
                        }}
                      >
                        {user.isCoinSeller ? "Already a Seller" : "Make Coin Seller"} {/* Conditionally render text */}
                      </Button>
                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">No Users Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog to input mobile number */}
      <Dialog
        open={openMobileDialog}
        onClose={handleMobileDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Enter Mobile Number</DialogTitle>
        <DialogContent>
          <TextField
            label="Mobile Number"
            value={mobile}
            onChange={(e) => setmobile(e.target.value)}
            fullWidth
            margin="dense"
            required
            InputLabelProps={{
              style: { color: "violet" }, // Label text color
            }}
            InputProps={{
              style: { color: "violet" }, // Input text color
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMobileDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMobileSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

{/* Dialog to give coins to a coin seller */}
<Dialog
  open={openCoinDialog}
  onClose={() => setOpenCoinDialog(false)}
  maxWidth="sm"
>
  <DialogTitle>Give Coins to Coin Seller</DialogTitle>
  <DialogContent>
    <TextField
      label="Coin Amount"
      type="number"
      value={coinAmount}
      onChange={(e) => setCoinAmount(e.target.value)}
      fullWidth
      margin="dense"
      InputLabelProps={{
        style: { color: "#8fe3eb" }, // Label text color
      }}
      InputProps={{
        style: { color: "#eb9f8f" }, // Input text color
      }}
    />
  </DialogContent>
  <DialogActions>
    <Button
      onClick={() => setOpenCoinDialog(false)}
      color="secondary"
    >
      Cancel
    </Button>
    <Button
      onClick={() => handleGiveCoin(selectedCoinSellerId, coinAmount)}
      color="primary"
    >
      Submit
    </Button>
  </DialogActions>
</Dialog>

{/* Dialog to remove from coin seller */}
<Dialog
  open={openDeleteDialog}
  onClose={() => setOpenDeleteDialog(false)}
  maxWidth="sm"
>
  <DialogTitle>Are you sure you want to remove seller</DialogTitle>
  {/* <DialogContent>
    <TextField
      label="Coin Amount"
      type="number"
      value={coinAmount}
      onChange={(e) => setCoinAmount(e.target.value)}
      fullWidth
      margin="dense"
      InputLabelProps={{
        style: { color: "#8fe3eb" }, // Label text color
      }}
      InputProps={{
        style: { color: "#eb9f8f" }, // Input text color
      }}
    />
  </DialogContent> */}
  <DialogActions>
    <Button
      onClick={() => setOpenDeleteDialog(false)}
      color="secondary"
    >
      Cancel
    </Button>
    <Button
      onClick={() => handleDeleteSeller(selectedSellerId)}
      color="primary"
    >
      Remove
    </Button>
  </DialogActions>
</Dialog>


    </>
  );
};

export default CoinSeller2;

































// import React, { useEffect, useState } from "react";
// import { Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
// } from "@material-ui/core";
// import Pagination from "../../pages/Pagination";
// import Male from "../../assets/images/male.png";
// import dayjs from "dayjs";

// import { baseURL, key } from "../../util/Config";

// const CoinSeller2 = () => {
//   const [data, setData] = useState([]);
//   const [activePage, setActivePage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [search, setSearch] = useState("ALL");
//   const [openDialog, setOpenDialog] = useState(false);
//   const [users, setUsers] = useState([]);
//   const [searchDialog, setSearchDialog] = useState("");

//   useEffect(() => {
//     // Fetch the coin sellers from your API (replace with the actual API call)
//     fetchCoinSellers();
//   }, []);

//   const fetchCoinSellers = async () => {
//     // Replace with actual API call logic
//     const response = await fetch("/api/getCoinSeller");
//     const result = await response.json();
//     setData(result);
//   };

//   const handleOpen = () => {
//     setOpenDialog(true); // Open the dialog
//     fetchUsersForCoinSeller(); // Fetch users when dialog opens
//   };

//   const handleClose = () => {
//     setOpenDialog(false); // Close the dialog
//   };

//   const fetchUsersForCoinSeller = async () => {
//     try {
//       const response = await fetch(`${baseURL}search-user-for-coin-seller`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           key: key, // Add key in headers
//         },
//       });
//       const result = await response.json();
//       console.log("result: ", result);
//       setUsers(result.users); // Assuming the API returns data in this format
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     }
//   };

//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//   };

//   const handleRowsPerPage = (value) => {
//     setActivePage(1);
//     setRowsPerPage(value);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value.toUpperCase();
//     if (value) {
//       const filteredData = data.filter((item) => {
//         return (
//           item?.name?.toUpperCase()?.indexOf(value) > -1 ||
//           item?.email?.toString()?.indexOf(value) > -1 ||
//           item?.receiveCoin?.toString()?.indexOf(value) > -1 ||
//           item?.spendCoin?.toString()?.indexOf(value) > -1
//         );
//       });
//       setData(filteredData);
//     } else {
//       setData(data);
//     }
//   };

//   const handleDialogSearch = (e) => {
//     const value = e.target.value.toLowerCase();
//     setSearchDialog(value);

//     if (value) {
//       const filteredUsers = users.filter((user) =>
//         user.name.toLowerCase().includes(value)
//       );
//       setUsers(filteredUsers);
//     } else {
//       fetchUsersForCoinSeller(); // Reset to the full user list if the search is empty
//     }
//   };

//   const makeCoinSeller = async (userId) => {
//     try {
//       const response = await fetch(`${baseURL}make-coin-seller`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           key: key,
//         },
//         body: JSON.stringify({
//           userId: userId, // Pass the user ID in the request body
//         }),
//       });
//       const result = await response.json();
//       console.log("Make Coin Seller Response:", result);
//       // Handle success or error response as needed
//       if (result.success) {
//         alert("User has been made a coin seller successfully.");
//       } else {
//         alert("Failed to make the user a coin seller.");
//       }
//     } catch (error) {
//       console.error("Error making coin seller:", error);
//     }
//   };

//   return (
//     <>
//       <div className="page-title">
//         <div className="row">
//           <div className="col-12 col-md-6 order-md-1 order-last">
//             <h3 className="mb-3" style={{ color: "#e4eeff" }}>
//               Coin Seller
//             </h3>
//           </div>
//           <div className="col-12 col-md-6 order-md-2 order-first">
//             <nav
//               aria-label="breadcrumb"
//               className="breadcrumb-header float-start float-lg-end"
//             >
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/admin/dashboard" className="text-danger">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item active " aria-current="page">
//                   CoinSeller
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col">
//           <div className="card" id="card">
//             <div className="card-header pb-0">
//               <div className="row my-3">
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//                   <button
//                     type="button"
//                     className="btn waves-effect waves-light btn-danger btn-sm float-left"
//                     onClick={handleOpen}
//                     id="CoinSellerAdd"
//                   >
//                     <i className="fa fa-plus"></i>
//                     <span className="icon_margin">New</span>
//                   </button>
//                 </div>
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
//                   <form action="">
//                     <div className="input-group mb-3 border rounded-pill">
//                       <div className="input-group-prepend border-0">
//                         <div id="button-addon4" className="btn text-danger">
//                           <i className="fas fa-search mt-2"></i>
//                         </div>
//                       </div>
//                       <input
//                         type="search"
//                         placeholder="What're you searching for?"
//                         aria-describedby="button-addon4"
//                         className="form-control bg-none border-0 rounded-pill searchBar"
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div className="card-body card-overflow pt-0">
//               <table className="table table-striped mt-5 text-center">
//                 <thead>
//                   <tr>
//                     <th>No.</th>
//                     <th>Image</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile No</th>
//                     <th>Receive Coin</th>
//                     <th>Spend Coin</th>
//                     <th>Coin</th>
//                     <th>Created At</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data && data.length > 0 ? (
//                     data.map((item, index) => (
//                       <tr key={index}>
//                         <td>{index + 1}</td>
//                         <td>
//                           <img
//                             height="50px"
//                             width="50px"
//                             alt="app"
//                             src={item?.image || Male}
//                             style={{
//                               boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
//                               border: "2px solid #fff",
//                               borderRadius: 10,
//                               float: "left",
//                               objectFit: "cover",
//                             }}
//                           />
//                         </td>
//                         <td>{item?.name}</td>
//                         <td>{item?.email}</td>
//                         <td>{item?.mobile}</td>
//                         <td>{item?.receiveCoin}</td>
//                         <td>{item?.spendCoin}</td>
//                         <td>{item?.coin}</td>
//                         <td>
//                           {dayjs(item?.createdAt).format("DD MMM YYYY")}
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <p>No Data Found</p>
//                   )}
//                 </tbody>
//               </table>
//               <div className="mt-2">
//                 <Pagination
//                   activePage={activePage}
//                   rowsPerPage={rowsPerPage}
//                   userTotal={data.length}
//                   handlePageChange={handlePageChange}
//                   handleRowsPerPage={handleRowsPerPage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Dialog to show users fetched for Coin Seller */}
//       <Dialog open={openDialog} onClose={handleClose}  minWidth="lg" maxWidth="lg" >
//         <DialogTitle>Choose Users for Coin Seller</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Search Users"
//             value={searchDialog}
//             onChange={handleDialogSearch}
//             fullWidth
//             margin="dense"
//             color="primary"
//             InputLabelProps={{
//               style: { color: 'red' }, // Label text color
//             }}
//             InputProps={{
//               style: { color: 'white' }, // Input text color
//             }}
//           />
//           <table className="table table-striped mt-3">
//             <thead>
//               <tr>
//                 <th>No.</th>
//                 <th>Name</th>
//                 <th>Email</th>
//                 <th>Mobile No</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users && users.length > 0 ? (
//                 users.map((user, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{user.name}</td>
//                     <td>{user.email}</td>
//                     <td>{user.mobile}</td>
//                     <td>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         onClick={() => makeCoinSeller(user._id)}
//                       >
//                         Make Coin Seller
//                       </Button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <p>No Users Found</p>
//               )}
//             </tbody>
//           </table>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default CoinSeller2;

































// ==================================================






// import React, { useEffect, useState } from "react";
// import { Tooltip } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   TextField,
// } from "@material-ui/core";
// import Pagination from "../../pages/Pagination";
// import Male from "../../assets/images/male.png";
// import dayjs from "dayjs";

// import { baseURL, key } from "../../util/Config";

// const CoinSeller2 = () => {
//   const [data, setData] = useState([]);
//   const [activePage, setActivePage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [search, setSearch] = useState("ALL");
//   const [openDialog, setOpenDialog] = useState(false);
//   const [users, setUsers] = useState([]);
//   const [searchDialog, setSearchDialog] = useState("");

//   useEffect(() => {
//     // Fetch the coin sellers from your API (replace with the actual API call)
//     fetchCoinSellers();
//   }, []);

//   const fetchCoinSellers = async () => {
//     // Replace with actual API call logic
//     // Assuming you fetch coin sellers here
//     const response = await fetch("/api/getCoinSeller");
//     const result = await response.json();
//     setData(result);
//   };

//   const handleOpen = () => {
//     setOpenDialog(true); // Open the dialog
//     fetchUsersForCoinSeller(); // Fetch users when dialog opens
//   };

//   const handleClose = () => {
//     setOpenDialog(false); // Close the dialog
//   };

//   const fetchUsersForCoinSeller = async () => {
//     try {
//       const response = await fetch(`${baseURL}search-user-for-coin-seller`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "key": key, // Add key in headers
//         },
//       });
//       const result = await response.json();
//       console.log("result: ", result);
//       setUsers(result.users); // Assuming the API returns data in this format
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     }
//   };
//   console.log("users: ", users);
//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//   };

//   const handleRowsPerPage = (value) => {
//     setActivePage(1);
//     setRowsPerPage(value);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value.toUpperCase();
//     if (value) {
//       const filteredData = data.filter((item) => {
//         return (
//           item?.name?.toUpperCase()?.indexOf(value) > -1 ||
//           item?.email?.toString()?.indexOf(value) > -1 ||
//           item?.receiveCoin?.toString()?.indexOf(value) > -1 ||
//           item?.spendCoin?.toString()?.indexOf(value) > -1
//         );
//       });
//       setData(filteredData);
//     } else {
//       setData(data);
//     }
//   };

//   const handleDialogSearch = (e) => {
//     const value = e.target.value.toLowerCase();
//     setSearchDialog(value);

//     if (value) {
//       const filteredUsers = users.filter((user) =>
//         user.name.toLowerCase().includes(value)
//       );
//       setUsers(filteredUsers);
//     } else {
//       fetchUsersForCoinSeller(); // Reset to the full user list if the search is empty
//     }
//   };

//   return (
//     <>
//       <div className="page-title">
//         <div className="row">
//           <div className="col-12 col-md-6 order-md-1 order-last">
//             <h3 className="mb-3" style={{ color: "#e4eeff" }}>
//               Coin Seller
//             </h3>
//           </div>
//           <div className="col-12 col-md-6 order-md-2 order-first">
//             <nav
//               aria-label="breadcrumb"
//               className="breadcrumb-header float-start float-lg-end"
//             >
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/admin/dashboard" className="text-danger">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item active " aria-current="page">
//                   CoinSeller
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col">
//           <div className="card" id="card">
//             <div className="card-header pb-0">
//               <div className="row my-3">
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//                   <button
//                     type="button"
//                     className="btn waves-effect waves-light btn-danger btn-sm float-left"
//                     onClick={handleOpen}
//                     id="CoinSellerAdd"
//                   >
//                     <i className="fa fa-plus"></i>
//                     <span className="icon_margin">New</span>
//                   </button>
//                 </div>
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
//                   <form action="">
//                     <div className="input-group mb-3 border rounded-pill">
//                       <div className="input-group-prepend border-0">
//                         <div id="button-addon4" className="btn text-danger">
//                           <i className="fas fa-search mt-2"></i>
//                         </div>
//                       </div>
//                       <input
//                         type="search"
//                         placeholder="What're you searching for?"
//                         aria-describedby="button-addon4"
//                         className="form-control bg-none border-0 rounded-pill searchBar"
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div className="card-body card-overflow pt-0">
//               <table className="table table-striped mt-5 text-center">
//                 <thead>
//                   <tr>
//                     <th>No.</th>
//                     <th>Image</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile No</th>
//                     <th>Receive Coin</th>
//                     <th>Spend Coin</th>
//                     <th>Coin</th>
//                     <th>Created At</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data &&  data.length > 0 ? (
//                     data.map((item, index) => (
//                       <tr key={index}>
//                         <td>{index + 1}</td>
//                         <td>
//                           <img
//                             height="50px"
//                             width="50px"
//                             alt="app"
//                             src={item?.image || Male}
//                             style={{
//                               boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
//                               border: "2px solid #fff",
//                               borderRadius: 10,
//                               float: "left",
//                               objectFit: "cover",
//                             }}
//                           />
//                         </td>
//                         <td>{item?.name}</td>
//                         <td>{item?.email}</td>
//                         <td>{item?.mobile}</td>
//                         <td>{item?.receiveCoin}</td>
//                         <td>{item?.spendCoin}</td>
//                         <td>{item?.coin}</td>
//                         <td>
//                           {dayjs(item?.createdAt).format("DD MMM YYYY")}
//                         </td>
//                       </tr>
//                     ))
//                   ) : (
//                     <p>No Data Found</p>
//                   )}
//                 </tbody>
//               </table>
//               <div className="mt-2">
//                 <Pagination
//                   activePage={activePage}
//                   rowsPerPage={rowsPerPage}
//                   userTotal={data.length}
//                   handlePageChange={handlePageChange}
//                   handleRowsPerPage={handleRowsPerPage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Dialog to show users fetched for Coin Seller */}
//       <Dialog open={openDialog} onClose={handleClose}>
//         <DialogTitle>Select Users</DialogTitle>
//         <DialogContent>
//           <TextField
//             fullWidth
//             placeholder="Search Users"
//             variant="outlined"
//             value={searchDialog}
//             onChange={handleDialogSearch}
//           />
//           <table className="table table-striped mt-3">
//             <thead>
//               <tr>
//                 <th>No.</th>
//                 <th>Name</th>
//                 <th>username</th>
//                 <th>Mobile No.</th>
//                 <th>create coinseller</th>
//               </tr>
//             </thead>
//             <tbody>
//               {users && users.length > 0 ? (
//                 users.map((user, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{user.name}</td>
//                     <td>{user.username}</td>
//                     <td>{user.mobile}</td>
//                     <td></td>
//                   </tr>
//                 ))
//               ) : (
//                 <p>No Users Found</p>
//               )}
//             </tbody>
//           </table>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Close</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default CoinSeller2;



























































// ======================================================










// import React, { useEffect, useState } from "react";
// import { Tooltip } from "@material-ui/core";
// import { Link, useHistory } from "react-router-dom";
// import { connect, useDispatch, useSelector } from "react-redux";

// import Pagination from "../../pages/Pagination";
// import Male from "../../assets/images/male.png";
// import {
//   getCoinSeller,
//   showCoinSeller,
//   deleteCoinSeller,
// } from "../../store/coinSeller2/action";
// import CoinSellerAdd from "../dialog/coinSellerAdd2";
// import CoinSellerAddCoin from "../dialog/CoinSellerAddCoin";
// import dayjs from "dayjs";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
// } from "@material-ui/core";

// const CoinSeller2 = (props) => {
//   const { coinSeller } = useSelector((state) => state.coinSeller);
//   const [data, setData] = useState([]);
//   const [activePage, setActivePage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [search, setSearch] = useState("ALL");
//   const [openDialog, setOpenDialog] = useState(false); // New state for dialog visibility
//   const dispatch = useDispatch();

//   useEffect(() => {
//     props.getCoinSeller();
//   }, []);

//   useEffect(() => {
//     setData(coinSeller);
//   }, [coinSeller]);

//   const handleOpen = () => {
//     setOpenDialog(true); // Open the dialog
//   };

//   const handleClose = () => {
//     setOpenDialog(false); // Close the dialog
//   };

//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//   };

//   const handleRowsPerPage = (value) => {
//     setActivePage(1);
//     setRowsPerPage(value);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value.toUpperCase();
//     if (value) {
//       const data = coinSeller.filter((data) => {
//         return (
//           data?.name?.toUpperCase()?.indexOf(value) > -1 ||
//           data?.email?.toString()?.indexOf(value) > -1 ||
//           data?.receiveCoin?.toString()?.indexOf(value) > -1 ||
//           data?.spendCoin?.toString()?.indexOf(value) > -1
//         );
//       });
//       setData(data);
//     } else {
//       setData(coinSeller);
//     }
//   };

//   const handleShow_ = (value) => {
//     props.showCoinSeller(value);
//   };

//   const handleDisable = (value) => {
//     props.deleteCoinSeller(value);
//   };



//   return (
//     <>
//       <div className="page-title">
//         <div className="row">
//           <div className="col-12 col-md-6 order-md-1 order-last">
//             <h3 className="mb-3" style={{ color: "#e4eeff" }}>
//               Coin Seller
//             </h3>
//           </div>
//           <div className="col-12 col-md-6 order-md-2 order-first">
//             <nav
//               aria-label="breadcrumb"
//               className="breadcrumb-header float-start float-lg-end"
//             >
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/admin/dashboard" className="text-danger">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item active " aria-current="page">
//                   CoinSeller
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div className="row">
//         <div className="col">
//           <div className="card" id="card">
//             <div className="card-header pb-0">
//               <div className="row my-3">
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//                   <button
//                     type="button"
//                     className="btn waves-effect waves-light btn-danger btn-sm float-left"
//                     onClick={handleOpen} // Updated to handleOpen
//                     id="CoinSellerAdd"
//                   >
//                     <i className="fa fa-plus"></i>
//                     <span className="icon_margin">New</span>
//                   </button>
//                 </div>
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
//                   <form action="">
//                     <div className="input-group mb-3 border rounded-pill">
//                       <div className="input-group-prepend border-0">
//                         <div id="button-addon4" className="btn text-danger">
//                           <i className="fas fa-search mt-2"></i>
//                         </div>
//                       </div>
//                       <input
//                         type="search"
//                         placeholder="What're you searching for?"
//                         aria-describedby="button-addon4"
//                         className="form-control bg-none border-0 rounded-pill searchBar"
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div className="card-body card-overflow pt-0">
//               <table className="table table-striped mt-5 text-center">
//                 <thead>
//                   <tr>
//                     <th>No.</th>
//                     <th>Image</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile No</th>
//                     <th>Receive Coin</th>
//                     <th>Spend Coin</th>
//                     <th>Coin</th>
//                     <th>Created At</th>
//                     <th>Is Show</th>
//                     <th>Is Accepted</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data.length > 0 ? (
//                     data.map((data, index) => {
//                       return (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <img
//                               height="50px"
//                               width="50px"
//                               alt="app"
//                               src={data?.image ? data?.image : Male}
//                               style={{
//                                 boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
//                                 border: "2px solid #fff",
//                                 borderRadius: 10,
//                                 float: "left",
//                                 objectFit: "cover",
//                               }}
//                             />
//                           </td>
//                           <td>{data?.name}</td>
//                           <td>{data?.email}</td>
//                           <td>{data?.mobile}</td>
//                           <td>{data?.receiveCoin}</td>
//                           <td>{data?.spendCoin}</td>
//                           <td>{data?.coin}</td>
//                           <td>
//                             {dayjs(data?.createdAt).format("DD MMM YYYY")}
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={data.isShow}
//                                 onChange={() => handleShow_(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       data?.isShow ? "-22px" : "35px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {data?.isShow ? "Yes" : "No"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={!data.isDisable}
//                                 onChange={() => handleDisable(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       data?.isDisable ? "-25px" : "28px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {data?.isDisable ? "No" : "Yes"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                         </tr>
//                       );
//                     })
//                   ) : (
//                     <p>No Data Found</p>
//                   )}
//                 </tbody>
//               </table>
//               <div className="mt-2">
//                 <Pagination
//                   activePage={activePage}
//                   rowsPerPage={rowsPerPage}
//                   userTotal={coinSeller.length}
//                   handlePageChange={handlePageChange}
//                   handleRowsPerPage={handleRowsPerPage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Dialog to add Coin Seller */}
//       <Dialog open={openDialog} onClose={handleClose}>
//         <DialogTitle>Add New Coin Seller</DialogTitle>
//         <DialogContent>
//           <CoinSellerAdd />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleClose} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <CoinSellerAddCoin />
//     </>
//   );
// };

// export default connect(
//   null,
//   { getCoinSeller, showCoinSeller, deleteCoinSeller }
// )(CoinSeller2);




// ====================================


// import { Tooltip } from "@material-ui/core";
// import React, { useEffect, useState } from "react";
// import { permissionError } from "../../util/Alert";
// import { Link, useHistory } from "react-router-dom";
// import Pagination from "../../pages/Pagination";
// import Male from "../../assets/images/male.png";
// import { connect, useDispatch, useSelector } from "react-redux";
// import {
//   getCoinSeller,
//   showCoinSeller,
//   deleteCoinSeller,
// } from "../../store/coinSeller2/action";
// import {
//   ADD_MONEY_OPEN_DIALOGUE,
//   OPEN_COINSELLER_DIALOGUE,
// } from "../../store/coinSeller2/type";
// import CoinSellerAdd from "../dialog/coinSellerAdd2";
// import dayjs from "dayjs";
// import CoinSellerAddCoin from "../dialog/CoinSellerAddCoin";

// const CoinSeller2 = (props) => {
//   const { coinSeller } = useSelector((state) => state.coinSeller);
//   const [data, setData] = useState([]);

//   const [activePage, setActivePage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [search, setSearch] = useState("ALL");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     props.getCoinSeller();
//   }, []);

//   useEffect(() => {
//     setData(coinSeller);
//   }, [coinSeller]);

//   const handleBlockUnblockSwitch_ = (data) => {
//     props.liveCut(data.liveStreamingId, data?.liveUserId?._id, data?.username);
//   };

//   const history = useHistory();

//   const handleUserInfo = (user) => {
//     history.push({ pathname: "/admin/user/detail", state: user });
//   };

//   const handleUserHistory = (user) => {
//     history.push({
//       pathname: "/admin/coinSeller2/history",
//       state: user,
//     });
//   };

//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//   };

//   const handleRowsPerPage = (value) => {
//     setActivePage(1);
//     setRowsPerPage(value);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value.toUpperCase();
//     if (value) {
//       const data = coinSeller.filter((data) => {
//         return (
//           data?.name?.toUpperCase()?.indexOf(value) > -1 ||
//           data?.email?.toString()?.indexOf(value) > -1 ||
//           data?.receiveCoin?.toString()?.indexOf(value) > -1 ||
//           data?.spendCoin?.toString()?.indexOf(value) > -1
//         );
//       });
//       setData(data);
//     } else {
//       setData(coinSeller);
//     }
//   };

//   // Handle opening of the CoinSellerAdd dialog
//   const handleOpen = () => {
//     dispatch({ type: OPEN_COINSELLER_DIALOGUE });
//   };

//   const handleEdit = (data) => {
//     dispatch({ type: OPEN_COINSELLER_DIALOGUE, payload: data });
//   };

//   const handleShow_ = (value) => {
//     props.showCoinSeller(value);
//   };

//   const handleDisable = (value) => {
//     props.deleteCoinSeller(value);
//   };

//   const handleGiveCoin = (value) => {
//     dispatch({ type: ADD_MONEY_OPEN_DIALOGUE, payload: value });
//   };

//   return (
//     <>
//       <div className="page-title">
//         <div className="row">
//           <div className="col-12 col-md-6 order-md-1 order-last">
//             <h3 className="mb-3" style={{ color: "#e4eeff" }}>
//               Coin Seller
//             </h3>
//           </div>
//           <div className="col-12 col-md-6 order-md-2 order-first">
//             <nav
//               aria-label="breadcrumb"
//               className="breadcrumb-header float-start float-lg-end"
//             >
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/admin/dashboard" className="text-danger">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item active " aria-current="page">
//                   CoinSeller
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div class="row">
//         <div class="col">
//           <div class="card" id="card">
//             <div className="card-header pb-0">
//               <div className="row my-3">
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//                   <button
//                     type="button"
//                     className="btn waves-effect waves-light btn-danger btn-sm float-left"
//                     onClick={handleOpen}  // Updated to handleOpen
//                     id="CoinSellerAdd"
//                   >
//                     <i className="fa fa-plus"></i>
//                     <span className="icon_margin">New</span>
//                   </button>
//                 </div>
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
//                   <form action="">
//                     <div className="input-group mb-3 border rounded-pill">
//                       <div className="input-group-prepend border-0">
//                         <div id="button-addon4" className="btn text-danger">
//                           <i className="fas fa-search mt-2"></i>
//                         </div>
//                       </div>
//                       <input
//                         type="search"
//                         placeholder="What're you searching for?"
//                         aria-describedby="button-addon4"
//                         className="form-control bg-none border-0 rounded-pill searchBar"
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div class="card-body card-overflow pt-0">
//               <table class="table table-striped mt-5 text-center">
//                 <thead>
//                   <tr>
//                     <th>No.</th>
//                     <th>Image</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile No</th>
//                     <th>Receive Coin</th>
//                     <th>Spend Coin</th>
//                     <th>Coin</th>
//                     <th>Give Coin </th>
//                     <th>Created At</th>
//                     <th>Is Show</th>
//                     <th>Is Accepted</th>
//                     <th>Edit</th>
//                     <th>History</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data.length > 0 ? (
//                     data.map((data, index) => {
//                       return (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <img
//                               height="50px"
//                               width="50px"
//                               alt="app"
//                               src={data?.image ? data?.image : Male}
//                               style={{
//                                 boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
//                                 border: "2px solid #fff",
//                                 borderRadius: 10,
//                                 float: "left",
//                                 objectFit: "cover",
//                               }}
//                             />
//                           </td>
//                           <td>{data?.name}</td>
//                           <td>{data?.email}</td>
//                           <td>{data?.mobile}</td>
//                           <td>{data?.receiveCoin}</td>
//                           <td>{data?.spendCoin}</td>
//                           <td>{data?.coin}</td>
//                           <td>
//                             <Tooltip title="Give Coin">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-info"
//                                 onClick={() => handleGiveCoin(data?._id)}
//                               >
//                                 <i className="fa fa-edit fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                           <td>
//                             {dayjs(data?.createdAt).format("DD MMM YYYY")}
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={data.isShow}
//                                 onChange={() => handleShow_(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       data?.isShow ? "-22px" : "35px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {data?.isShow ? "Yes" : "No"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={!data.isDisable}
//                                 onChange={() => handleDisable(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       data?.isDisable ? "-25px" : "28px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {data?.isDisable ? "No" : "Yes"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                           <td>
//                             <Tooltip title="Edit">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-info"
//                                 onClick={() => handleEdit(data)}
//                               >
//                                 <i className="fa fa-edit fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                           <td>
//                             <Tooltip title="History">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-info"
//                                 onClick={() => handleUserHistory(data)}
//                               >
//                                 <i className="fa fa-history fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                         </tr>
//                       );
//                     })
//                   ) : (
//                     <p>No Data Found</p>
//                   )}
//                 </tbody>
//               </table>
//               <div class="mt-2">
//                 <Pagination
//                   activePage={activePage}
//                   rowsPerPage={rowsPerPage}
//                   userTotal={coinSeller.length}
//                   handlePageChange={handlePageChange}
//                   handleRowsPerPage={handleRowsPerPage}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Render the CoinSellerAdd dialog */}
//       <CoinSellerAdd />
//       <CoinSellerAddCoin />
//     </>
//   );
// };

// export default connect(null, { getCoinSeller, showCoinSeller, deleteCoinSeller })(
//   CoinSeller2
// );

// ===================================






// import { Tooltip } from "@material-ui/core";
// import React, { useEffect, useState } from "react";

// import { permissionError } from "../../util/Alert";
// //routing
// import { Link, useHistory } from "react-router-dom";
// //pagination
// import Pagination from "../../pages/Pagination";
// //image
// import Male from "../../assets/images/male.png";
// import { connect, useDispatch, useSelector } from "react-redux";

// import {
//   getCoinSeller,
//   showCoinSeller,
//   deleteCoinSeller,
// } from "../../store/coinSeller2/action";
// import {
//   ADD_MONEY_OPEN_DIALOGUE,
//   OPEN_COINSELLER_DIALOGUE,
// } from "../../store/coinSeller2/type";
// import CoinSellerAdd from "../dialog/coinSellerAdd";
// import dayjs from "dayjs";
// import CoinSellerAddCoin from "../dialog/CoinSellerAddCoin";

// const CoinSeller2 = (props) => {
//   const { coinSeller } = useSelector((state) => state.coinSeller);
//   const [data, setData] = useState([]);

//   const [activePage, setActivePage] = useState(1);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [search, setSearch] = useState("ALL");
//   const dispatch = useDispatch();

//   useEffect(() => {
//     props.getCoinSeller();
//   }, []);

//   useEffect(() => {
//     setData(coinSeller);
//   }, [coinSeller]);

//   const handleBlockUnblockSwitch_ = (data) => {
//     props.liveCut(data.liveStreamingId, data?.liveUserId?._id, data?.username);
//   };

//   const history = useHistory();

//   const handleUserInfo = (user) => {
//     history.push({ pathname: "/admin/user/detail", state: user });
//   };

//   const handleUserHistory = (user) => {
//     console.log("user", user);
//     history.push({
//       pathname: "/admin/coinSeller2/history",
//       state: user,
//     });
//   };

//   //   pagination

//   const handlePageChange = (pageNumber) => {
//     setActivePage(pageNumber);
//   };

//   const handleRowsPerPage = (value) => {
//     setActivePage(1);
//     setRowsPerPage(value);
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value.toUpperCase();
//     if (value) {
//       const data = coinSeller.filter((data) => {
//         return (
//           data?.name?.toUpperCase()?.indexOf(value) > -1 ||
//           data?.email?.toString()?.indexOf(value) > -1 ||
//           data?.receiveCoin?.toString()?.indexOf(value) > -1 ||
//           data?.spendCoin?.toString()?.indexOf(value) > -1
//         );
//       });
//       setData(data);
//     } else {
//       setData(coinSeller);
//     }
//   };

//   const handleOpen = () => {
//     dispatch({ type: OPEN_COINSELLER_DIALOGUE });
//   };
//   const handleEdit = (data) => {
//     dispatch({ type: OPEN_COINSELLER_DIALOGUE, payload: data });
//   };

//   const handleShow_ = (value) => {
//     props.showCoinSeller(value);
//   };

//   const handleDisable = (value) => {
//     props.deleteCoinSeller(value);
//   };

//   const handleGiveCoin = (value) => {
//     dispatch({ type: ADD_MONEY_OPEN_DIALOGUE, payload: value });
//   };

//   return (
//     <>
//       <div className="page-title">
//         <div className="row">
//           <div className="col-12 col-md-6 order-md-1 order-last">
//             <h3 className="mb-3" style={{ color: "#e4eeff" }}>
//               Coin Seller
//             </h3>
//           </div>
//           <div className="col-12 col-md-6 order-md-2 order-first">
//             <nav
//               aria-label="breadcrumb"
//               className="breadcrumb-header float-start float-lg-end"
//             >
//               <ol className="breadcrumb">
//                 <li className="breadcrumb-item">
//                   <Link to="/admin/dashboard" className="text-danger">
//                     Dashboard
//                   </Link>
//                 </li>
//                 <li className="breadcrumb-item active " aria-current="page">
//                   CoinSeller
//                 </li>
//               </ol>
//             </nav>
//           </div>
//         </div>
//       </div>
//       <div class="row">
//         <div class="col">
//           <div class="card" id="card">
//             <div className="card-header pb-0">
//               <div className="row my-3">
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
//                   <button
//                     type="button"
//                     className="btn waves-effect waves-light btn-danger btn-sm float-left"
//                     onClick={handleOpen}
//                     id="CoinSellerAdd"
//                   >
//                     <i className="fa fa-plus"></i>
//                     <span className="icon_margin">New</span>
//                   </button>
//                 </div>
//                 <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
//                   <form action="">
//                     <div className="input-group mb-3 border rounded-pill">
//                       <div className="input-group-prepend border-0">
//                         <div id="button-addon4" className="btn text-danger">
//                           <i className="fas fa-search mt-2"></i>
//                         </div>
//                       </div>
//                       <input
//                         type="search"
//                         placeholder="What're you searching for?"
//                         aria-describedby="button-addon4"
//                         className="form-control bg-none border-0 rounded-pill searchBar"
//                         onChange={handleSearch}
//                       />
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//             <div class="card-body card-overflow pt-0">
//               <table class="table table-striped mt-5 text-center">
//                 <thead>
//                   <tr>
//                     <th>No.</th>
//                     <th>Image</th>
//                     <th>Name</th>
//                     <th>Email</th>
//                     <th>Mobile No</th>
//                     <th>Receive Coin</th>
//                     <th>Spend Coin</th>
//                     <th>Coin</th>
//                     <th>Give Coin </th>
//                     <th>Created At</th>
//                     <th>Is Show</th>
//                     <th>Is Accepted</th>
//                     <th>Edit</th>
//                     <th>History</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data.length > 0 ? (
//                     data.map((data, index) => {
//                       return (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <img
//                               height="50px"
//                               width="50px"
//                               alt="app"
//                               src={data?.image ? data?.image : Male}
//                               style={{
//                                 boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
//                                 border: "2px solid #fff",
//                                 borderRadius: 10,
//                                 float: "left",
//                                 objectFit: "cover",
//                               }}
//                             />
//                           </td>
//                           <td>{data?.name}</td>
//                           <td>{data?.email}</td>
//                           <td>{data?.mobile}</td>
//                           <td>{data?.receiveCoin}</td>
//                           <td>{data?.spendCoin}</td>
//                           <td>{data?.coin}</td>
//                           <td>
//                             <Tooltip title="Give Coin">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-info"
//                                 onClick={() => handleGiveCoin(data?._id)}
//                               >
//                                 <i className="fa fa-edit fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                           <td>
//                             {dayjs(data?.createdAt).format("DD MMM YYYY")}
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={data.isShow}
//                                 onChange={() => handleShow_(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       data?.isShow ? "-22px" : "35px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {data?.isShow ? "Yes" : "No"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                           <td>
//                             <label className="switch">
//                               <input
//                                 type="checkbox"
//                                 checked={!data.isDisable}
//                                 onChange={() => handleDisable(data._id)}
//                               />
//                               <span className="slider">
//                                 <p
//                                   style={{
//                                     fontSize: 12,
//                                     marginLeft: `${
//                                       !data?.isDisable ? "-22px" : "35px"
//                                     }`,
//                                     color: "#000",
//                                     marginTop: "6px",
//                                   }}
//                                 >
//                                   {!data?.isDisable ? "Yes" : "No"}
//                                 </p>
//                               </span>
//                             </label>
//                           </td>
//                           <td>
//                             <Tooltip title="Edit">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-info"
//                                 onClick={() => handleEdit(data)}
//                               >
//                                 <i className="fa fa-edit fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                           <td>
//                             <Tooltip title="History">
//                               <button
//                                 type="button"
//                                 className="btn btn-sm btn-success"
//                                 onClick={() => handleUserHistory(data)}
//                               >
//                                 <i className="fas fa-history fa-lg"></i>
//                               </button>
//                             </Tooltip>
//                           </td>
//                         </tr>
//                       );
//                     })
//                   ) : (
//                     <tr>
//                       <td colSpan="20" align="center">
//                         Nothing to show!!
//                       </td>
//                     </tr>
//                   )}
//                 </tbody>
//               </table>

//               <Pagination
//                 activePage={activePage}
//                 rowsPerPage={rowsPerPage}
//                 userTotal={data?.length}
//                 handleRowsPerPage={handleRowsPerPage}
//                 handlePageChange={handlePageChange}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <CoinSellerAdd />
//       <CoinSellerAddCoin />
//     </>
//   );
// };

// export default connect(null, {
//   getCoinSeller,
//   deleteCoinSeller,
//   showCoinSeller,
// })(CoinSeller2);
